export const environment = {
  version: '2.26.0-rc5',
  production: false,
  apiUrl: 'https://api.digipilote.eu/api/v1/',
  socketUrl: 'https://api.digipilote.eu',
  backOfficeUrl: 'https://bo.digipilote.eu',
  name: 'local',
  sentryDsn: 'https://3d54b5bfae29498697c0c8033ec19acb@o209398.ingest.sentry.io/5319333',
  googleAnalyticsId: ''
};
