import { Component, Optional, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './authentication/services/auth.service';
import { ConfigService } from './config/services/config.service';


declare let gtag: Function;
@Component({
    selector: 'chankya-app',
    template: `<router-outlet></router-outlet>`,
    encapsulation: ViewEncapsulation.None
})



export class ChankyaAppComponent {

    constructor(translate: TranslateService,
        public router: Router,
        public configService: ConfigService,
        private authService: AuthService) {

        this.authService.loginCommands = ['/auth'];
        this.authService.afterLoginCommands = ['/dashboard'];
        this.authService.logoutCommands = ['/auth'];
        this.authService.changePasswordCommands = ['/change-password'];

        this.router.events.subscribe( event => {
            if (event instanceof NavigationEnd) {
                gtag('config', configService.googleAnalyticsId, {
                    'page_path': event.urlAfterRedirects
                });
                
            }
        })

        // translate.addLangs(['fr', 'en']);
        // translate.setDefaultLang('en');
        // const browserLang: string = translate.getBrowserLang();
        // translate.use(browserLang.match(/fr|en/) ? browserLang : 'fr');
    }
}