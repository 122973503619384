import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
//
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/internal/operators';
//
import { ErrorHandlingService } from '../services/error-handling.service';
import { AuthService } from '../../authentication/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlingInterceptorService implements HttpInterceptor {

    constructor(
        private errorHandlingService: ErrorHandlingService,
        private authService: AuthService,
        private location: Location,
        private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        // tslint:disable-next-line:no-shadowed-variable
        return next.handle(request).pipe(tap(request => {
            if (request instanceof HttpResponse) {
                this.errorHandlingService.serverOnline.next(true);
            }
            return request;
        }), catchError((error: HttpErrorResponse) => {
            // Common place where put code we want to execute for all the request when are success
            if (error.status === 401) {
                this.authService.loginNavigationExtras = { queryParams: { returnUrl: this.location.path() } };
                this.router.navigate(this.authService.logoutCommands);
            }
            return throwError(this.errorHandlingService.handleServiceError(error));
        }));
    }
}
