import { NavigationExtras, Router } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
//
import { BehaviorSubject, empty, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as jwt_decode from 'jwt-decode';
//
import { ConfigService } from '../../config/services/config.service';
import { ErrorHandlingService } from '../../error-handling/services/error-handling.service';
import { ErrorHandlingHttpService } from '../../error-handling/services/error-handling-http.service';
import { LoginUser } from '../models/login-user';
import { Setting } from '../models/setting';
import { Language } from 'app/frontoffice/modules/dp-language/models/language';
import { UserSignUp } from '../models/user-sign-up';
import { User } from 'app/frontoffice/modules/dp-user/models/user';
import { ContactUs } from '../models/contact-us';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {

    user: any;
    userDataSto: any;
    isLoggedIn = false;
    loginCommands: any[];
    loginNavigationExtras?: NavigationExtras;
    redirectCommands: any[];
    redirectNavigationExtras?: NavigationExtras;
    afterLoginCommands: any[];
    afterLoginNavigationExtras?: NavigationExtras;
    changePasswordCommands: any[];
    changePasswordNavigationExtras: NavigationExtras;
    public userSettings: Setting[];
    public userFullName$ = new BehaviorSubject<string>(null);
    public twoFactorAuthModalData$ = new BehaviorSubject<any>(null);
    public twoFactorAuthModalNavigation$ = new BehaviorSubject<string>(null);
    subscriptions: Subscription[] = [];
    private userSource = new BehaviorSubject<any>(null);
    public userData = this.userSource.asObservable();
    private _languages: Language[];
    public languageChanged: BehaviorSubject<any>;

    constructor(private http: ErrorHandlingHttpService,
        private errorHandlingService: ErrorHandlingService,
        private router: Router,
        private toastr: ToastrService,
        private configService: ConfigService,
        private translate: TranslateService,
    ) {

        this.languageChanged = new BehaviorSubject<any>(this.defaultLang);
        this.errorHandlingService.tokenExpired.subscribe((expired) => {
            if (expired) {
                const user = JSON.parse(localStorage.getItem('currentUser'));
                this.currentUser = user;
            }
        });

        this.http.logout.subscribe((logout) => {
            if (logout) {
                this.logOut();
            }
        });
    }

    get logoutCommands(): any[] {
        return this.errorHandlingService.logoutCommands;
    }

    set logoutCommands(value: any[]) {
        this.errorHandlingService.logoutCommands = value;
    }

    get logoutNavigationExtras(): NavigationExtras {
        return this.errorHandlingService.logoutNavigationExtras;
    }

    set logoutNavigationExtras(value: NavigationExtras) {
        this.errorHandlingService.logoutNavigationExtras = value;
    }

    get userToken(): string {
        return this.http.userToken;
    }

    set userToken(value: string) {
        this.http.userToken = value;
    }

    get currentLang(): string {
        return this.http.currentLanguage;
    }

    set currentLang(value: string) {
        this.http.currentLanguage = value;
    }

    get userLanguages() {
        return this._languages;
    }

    set userLanguages(languages: Language[]) {
        this._languages = languages;
        if (languages && languages.length > 0) {
            this.translate.addLangs(languages.map(language => language.code));
        }
    }

    get currentUser(): any {
        let storageUser = sessionStorage.getItem('currentUser');
        if (!storageUser) {
            storageUser = localStorage.getItem('currentUser');
            sessionStorage.setItem('currentUser', storageUser);
        }
        if (storageUser && storageUser !== 'null') {
            return JSON.parse(storageUser);
        }
        return null;
    }

    set currentUser(value: any) {
        localStorage.setItem('currentUser', value ? JSON.stringify(value) : null);
        sessionStorage.setItem('currentUser', value ? JSON.stringify(value) : null);
        this.userSource.next(value);
        this.userFullName$.next(this.loggedUserInfo());
    }

    set defaultLang(language: string) {
        localStorage.setItem('default_language', language);
    }

    get defaultLang() {
        if (localStorage.getItem('default_language')) {
            return localStorage.getItem('default_language')
        }
        if (this.userSettings) {
            const setting = this.userSettings.find(s => s.settingKey === 'default_language');
            if (setting) {
                return setting.settingValue;
            }
        }
        return '';
    }

    get isPublicLanguage() {
        return localStorage.getItem('public') ? true : false;
    }

    set notPublicLanguage(language: string) {
        this.defaultLang = language;
        if (this.isPublicLanguage) {
            localStorage.removeItem('public');
        }
    }

    set publicLanguage(language: string) {
        this.defaultLang = language;
        if (!this.isPublicLanguage) {
            localStorage.setItem('public', 'true');
        }
    }

    get isAuthenticated(): boolean {
        // return this.currentUser && this.currentUser !== 'null' ? true : false;
        return this.userToken && this.userToken !== 'null' ? true : false;

    }

    getHeaders(addUserOauth = false, isForm = false, addAuthorization = true): HttpHeaders {
        let requestOptions = new HttpHeaders({
            'Accept-Language': this.currentLang,
            'Content-Type': isForm ? 'application/x-www-form-urlencoded' : 'application/json',
        });

        if (this.userToken && this.userToken !== 'null') { // check 'null' because is returned this value when not exists
            if (addAuthorization) {
                requestOptions = requestOptions.append('Authorization', 'Bearer ' + this.userToken);
            }

            if (addUserOauth) {
                requestOptions = requestOptions.append('useroauth', this.userToken);
            }
        }
        return requestOptions;
    }

    updateCurrentUser(value: any) {
        this.userSource.next(value);
        localStorage.setItem('currentUser', value ? JSON.stringify(value) : null);

    }

    getDecodedAccessToken(): any {
        return jwt_decode(this.userToken);
    }

    getUserRole() {
        try {
            const userRole: string = this.getDecodedAccessToken().role;
            return userRole;
        } catch (Error) {
            return null;
        }
    }

    getUser() {
        try {
            const user: User = this.getDecodedAccessToken();
            return user;
        } catch (Error) {
            return null;
        }
    }

    getCompanyInstanceId() {
        try {
            const instanceId: string = this.getDecodedAccessToken().instanceId;
            return instanceId;
        } catch (Error) {
            return null;
        }
    }

    loggedUserInfo() {
        const value = this.currentUser;
        let userFullname = null;

        if (value) {
            userFullname = value.first_name && value.last_name ? `${value.first_name} ${value.last_name}` : null;
            if (!userFullname) {
                userFullname = value.first_name ? value.first_name : value.last_name;
                userFullname = userFullname ? userFullname : value.username;
            }
        }
        return userFullname;
    }

    hasWritePermission(): boolean {
        return this.currentUser &&
            ((this.currentUser.role !== 'company' && this.currentUser.role !== 'admin_company') ||
                (this.currentUser.permission === 'write' && this.currentUser.companyPermission === 'write'));
    }

    getLoggedUserInfo() {
        return this.currentUser;
    }

    loginUser(email: string, password: string, code?: string): Observable<any> {
        password = password.replace('+', '%2B');

        const headers = this.getHeaders(false, false, false);
        const credentials = {
            'email': email,
            'password': password
        };

        const url: string = this.configService.apiUrl + this.configService.config.apiConfigs.authentication.loginUser.apiEndpoint;
        return this.http.post<LoginUser>(url, credentials, { headers: headers }).pipe(map((response: LoginUser) => {
            return this.afterLoginUser(response);
        }));
    }

    afterLoginUser(response: LoginUser) {
        const token = response.token;
        if (!token || token.length === 0) {
            throw new Error();
        }
        this.userToken = token;
        const { user } = response;
        if (response) {
            this.currentUser = user;
            this.userToken = token;
        } else {
            this.currentUser = null;
            throw new Error();
        }
        return empty();
    }

    logOut(): void {
        /*let headers = this.getHeaders(false, true, false);
        let credentials = 'grant_type=password'
            + '&token=' + this.userToken;
        // Using the builtin Http of angular for prevent handling the errors and showing messages to the user
        this.http.httpClient.post(this.configService.config.apiConfigs.authentication.revokeUser.apiEndpoint,
            credentials, { headers: headers }).subscribe();*/
        this.logoutSpa();
        this.router.navigate(this.logoutCommands);
    }

    postUserSettings(settings: any): Observable<any> {
        const headers = this.getHeaders(true);
        return this.http.post(this.configService.apiUrl + this.configService.config.apiConfigs.authentication.userSettings.apiEndpoint,
            JSON.stringify(settings));
    }

    getUserSettings(): Observable<any> {
        const headers = this.getHeaders(true);
        return this.http.get<any>(this.configService.apiUrl + this.configService.config.apiConfigs.authentication.userSettings.apiEndpoint,
            { headers: headers })
            .pipe(map((settings: Setting[]) => {
                this.userSettings = settings;
                const defaultLanguageSetting = settings.find(s => s.settingKey === 'default_language');
                const language = defaultLanguageSetting ? defaultLanguageSetting.settingValue : null;
                if (language && (!this.defaultLang || this.isPublicLanguage)) {
                    this.notPublicLanguage = language;
                }
                return this.userSettings;
            }));
    }

    getPublicDefaultLanguage(): Observable<any> {
        const headers = this.getHeaders(true);
        return this.http.get<any>(this.configService.apiUrl + this.configService.config.apiConfigs.authentication.userSettings.apiEndpoint + '/public',
            { headers: headers })
            .pipe(map((settings: Setting[]) => {
                const defaultLanguageSetting = settings.find(s => s.settingKey === 'default_language');
                const language = defaultLanguageSetting ? defaultLanguageSetting.settingValue : 'en';
                if (language && !this.defaultLang) {
                    this.publicLanguage = language;
                }
                return language;
            }));
    }

    getUserSettingByKey(key: string) {
        if (this.userSettings) {
            const setting = this.userSettings.find(s => s.settingKey === key);
            if (setting) {
                return setting.settingValue;
            }
        }
        if (key === 'default_language') {
            return 'en';
        }
        if (key === 'answer_amount') {
            return '4';
        }
        return '';
    }

    logoutSpa(): Observable<{}> {
        const token = localStorage.getItem('userToken');
        localStorage.removeItem('userToken');
        sessionStorage.removeItem('userToken');
        this.isLoggedIn = false;
        if (token) { // if didn't logout before
            const subTranslate = this.translate.get('AUTH_LOGOUT_MESSAGE').subscribe((res: string) => {
                this.toastr.success(res, '', {progressBar: true});
            });
            this.subscriptions.push(subTranslate);
        }
        this.userToken = null;
        this.currentUser = null;
        return empty();
    }

    updateExpirationTime(data): Observable<any> {
        return this.http.patch(this.configService.config.apiConfigs.authentication.tokenExpirationTime.apiEndpoint,
            data, { headers: this.getHeaders(true) });
    }

    /*
   * forgotPassword is used send email to reset your password.
   */
    forgotPassword(email, language?: string): Observable<any> {
        const data = {
            email: email,
            language,
        };
        const url = this.configService.apiUrl + this.configService.config.apiConfigs.authentication.forgotPassword.apiEndpoint;
        return this.http.post<any>(url, JSON.stringify(data));
    }

    /*
    * resetPassword is used to reset your password.
    */

    resetPassword(password: string, token: string, language?: string): Observable<any> {
        const data = {
            password: password
        };

        let queryParams = '';
        if (language) {
            queryParams = `?lang=${language}`;
        }

        const url = this.configService.apiUrl + this.configService.config.apiConfigs.authentication.resetPassword.apiEndpoint;
        return this.http.post<any>(url + '/' + token + queryParams, JSON.stringify(data));
    }

    changePassword(newPassword: string): Observable<any> {
        return this.http.patch<any>(this.configService.config.apiConfigs.authentication.newPassword.apiEndpoint, {
            new_password: newPassword
        }, { headers: this.getHeaders() });
    }

    patchUser(data: any, userId: string): Observable<any> {
        return this.http.patch<any>(this.configService.config.apiConfigs.users.apiEndpoint + userId + '/', JSON.stringify(data));
    }

    passUserData(user: any) {
        this.userSource.next(user);
    }

    /*
  *  getLocalStorageUser function is used to get local user profile data.
  */

    getStorageUser() {
        let token = sessionStorage.getItem('userToken');
        if (!token || token === 'null') {
            token = localStorage.getItem('userToken');
            sessionStorage.setItem('userToken', token);
            sessionStorage.setItem('currentUser', localStorage.getItem('currentUser'));
        }
        this.userData = JSON.parse(token);
        if (this.userData) {
            this.isLoggedIn = true;
            return true;
        } else {
            this.isLoggedIn = false;
            return false;
        }
    }


    logOutPas() {
        localStorage.removeItem('userToken');
        sessionStorage.removeItem('userToken');
        this.isLoggedIn = false;
        const subTranslate = this.translate.get('AUTH_LOGOUT_MESSAGE').subscribe((res: string) => {
            this.toastr.success(res, '', {progressBar: true});
        });
        this.router.navigate(['/session/loginone']);
        this.subscriptions.push(subTranslate);
    }

    /*
     * setLocalUserProfile function is used to set local user profile data.
     */
    setLocalUserProfile(value) {
        localStorage.setItem('userToken', JSON.stringify(value));
        sessionStorage.setItem('userToken', JSON.stringify(value));
        this.isLoggedIn = true;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => {
            s.unsubscribe();
        })
    }

    confirmInvitation(
        user: UserSignUp | { userId: string },
        token: string): Observable<any> {
        const url = this.configService.apiUrl + this.configService.config.apiConfigs.invitations.apiEndpoint;
        return this.http.post<any>(url + '/confirm/' + token, JSON.stringify(user));
    }


    putContactUs(data: ContactUs): Observable<any> {
        const url: string = this.configService.apiUrl + this.configService.config.apiConfigs.users.apiEndpoint;
        return this.http.post<any>(url + 'public-support', JSON.stringify(data));
    }

}
