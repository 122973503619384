import { Component, OnInit } from '@angular/core';



@Component({
    selector: 'app-terms-conditions',
    templateUrl: './terms-conditions.component.html',
    styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

    text = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN">
    <HTML>
    <HEAD>
        <META HTTP-EQUIV="CONTENT-TYPE" CONTENT="text/html; charset=utf-8">
        <TITLE></TITLE>
        <META NAME="GENERATOR" CONTENT="LibreOffice 4.1.6.2 (Linux)">
        <META NAME="AUTHOR" CONTENT="REBERT Nathalie">
        <META NAME="CREATED" CONTENT="20200707;110100000000000">
        <META NAME="CHANGEDBY" CONTENT="AIM Michael">
        <META NAME="CHANGED" CONTENT="20200707;110100000000000">
        <META NAME="AppVersion" CONTENT="16.0000">
        <META NAME="Company" CONTENT="CCI Bourgogne Franche Comté">
        <META NAME="ContentTypeId" CONTENT="0x0101004AFC2ECF4803DB4B9BA3D1BD946DC13B">
        <META NAME="DocSecurity" CONTENT="0">
        <META NAME="HyperlinksChanged" CONTENT="false">
        <META NAME="LinksUpToDate" CONTENT="false">
        <META NAME="ScaleCrop" CONTENT="false">
        <META NAME="ShareDoc" CONTENT="false">
        <STYLE TYPE="text/css">
        <!--
            @page { margin-left: 0.98in; margin-right: 0.98in; margin-top: 0.49in; margin-bottom: 0.49in }
            P { margin-bottom: 0.08in; direction: ltr; widows: 2; orphans: 2 }
            A:link { color: #0000ff; so-language: zxx }
        -->
        </STYLE>
    </HEAD>
    <BODY LANG="fr-FR" LINK="#0000ff" DIR="LTR">
    <DIV TYPE=HEADER>
        <P STYLE="margin-bottom: 0.45in; line-height: 100%"><BR>
        </P>
    </DIV>
    <P ALIGN=CENTER STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">DIGIPILOTE</FONT></P>
    <P ALIGN=CENTER STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">CONDITIONS
    GENERALES D’UTILISATION DIGIPILOTE</FONT></P>
    <P ALIGN=CENTER STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in"><FONT FACE="Arial, serif">Dernière
    version&nbsp;: </FONT><FONT FACE="Arial, serif">Mises en ligne le
    …………………… 7 juillet 2020 </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Préambule
    </B></FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT COLOR="#000000"><FONT FACE="Arial, serif"><SPAN STYLE="background: #ffffff">Les
    présentes </SPAN></FONT></FONT><FONT COLOR="#000000"><FONT FACE="Arial, serif"><B><SPAN STYLE="background: #ffffff">CONDITION
    GENERALES D’UTILISATION</SPAN></B></FONT></FONT><FONT COLOR="#000000"><FONT FACE="Arial, serif"><SPAN STYLE="background: #ffffff">
    (CGU) ont pour objet de définir les modalités et les conditions
    d’accès et d’utilisation des services disponibles via la
    Plateforme DIGIPILOTE&nbsp;; ainsi que de définir le rôle et les
    obligations de chacun. </SPAN></FONT></FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Les
    présentes CGU constituent un accord contractuel entre l’ensemble
    des utilisateurs et CCI France (ci-après dénommé « Le Contrat »).</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Tout
    accès aux Services et à l’utilisation de la Plateforme suppose la
    connaissance des CGU détaillées au sein du présent Contrat, et
    entraîne leur acceptation sans restriction ni réserve. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L'accès
    à certaines fonctionnalités des services de la Plateforme et leur
    utilisation pourront être soumis à des Conditions d’utilisation
    Particulières et/ou à des Conditions Générales de Ventes qui
    seront formalisées au sein de documents. Ces documents font partie
    intégrante du présent Contrat, l’utilisateur s'engage à les
    connaître et à les respecter en toutes circonstances. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Editeur
        et hébergeur de la Plateforme, directeur de publication</B></FONT></P>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Accessible
    à l’adresse www.digipilote.eu, la Plateforme web « DIGIPILOTE »
    est éditée, pour le compte des CCI de France et de CCI France, par
    CCI France, établissement public Administratif de l'Etat, sise 8-10
    rue Pierre Brossolette - CS 90166 - 92 309 Levallois-Perret -  Tél :
    01 44 45 38 40.  </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Le
    directeur de la publication est Pierre Goguet, président de CCI
    France. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">La
    plateforme est hébergée par la société SMILE,</FONT> <FONT FACE="Arial, serif">1er
    intégrateur Français et Européen de solutions open source - Siège
    social : 20 rue des Jardins - 92600 Asnières-sur-Seine - Téléphone
    : 01 41 40 11 00.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=2>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Définitions</B></FONT></P>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><A NAME="_Hlk42004709"></A>
    <FONT FACE="Arial, serif">Pour l’application des présentes
    Conditions Générales d’Utilisation, les mots et expressions
    ci-après auront la signification ci-dessous lorsqu’ils seront
    orthographiés en lettres majuscules ou avec la première lettre du
    mot ou expressions en lettre majuscule. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Ils
    auront la même signification qu’ils soient employés au pluriel ou
    au singulier :</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Conditions Générales
        d’Utilisation / CGU : désignent et signifient les présentes
        conditions générales d’utilisation. </FONT></FONT>
        </P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Marque DIGIPILOTE :
        désigne et signifie la marque française No 20 4&nbsp;633 833 et
        toute autre marque portant sur le signe « DIGIPILOTE » dont CCI
        France est ou sera titulaire.</FONT></FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Internaute&nbsp;:
        désigne et signifie la personne physique ou morale qui consulte la
        plateforme Digipilote sans s’authentifier.</FONT></FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Plateforme : désigne
        et signifie le site web accessible à </FONT></FONT><FONT FACE="Arial, serif">l’adresse
        URL</FONT><FONT COLOR="#000000"><FONT FACE="Arial, serif">
        </FONT></FONT><FONT FACE="Arial, serif">www.digipilote.eu</FONT><FONT COLOR="#000000"><FONT FACE="Arial, serif">
        ainsi que les pages affiliées.</FONT></FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Service : désigne
        et signifie le logiciel SAAS, l’application mobile ou la Web app
        proposant un service 100% dématérialisé et accessible via la
        Plateforme. </FONT></FONT>
        </P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Utilisateur :
        désigne et signifie la personne physique ou morale qui consulte et
        utilise les Services Digipilote en s’étant préalablement
        authentifié.</FONT></FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Licence
        utilisateur&nbsp;: désigne le droit accordé à l’utilisateur
        d’utiliser les Services Digipilote dans les conditions définies
        dans les présentes CGU et dans les Conditions Générales de Vente</FONT></FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Licence Tiers&nbsp;:
        désigne le droit accordé à une Entité Tierce d’autoriser
        l’utilisation des Services Digipilote à un nombre d’utilisateurs
        défini dans les Conditions Générales de Vvente.</FONT></FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Utilisateur
        Entreprise : désigne la personne physique ayant un droit d’accès
        octroyé par l’Administrateur Entreprise. </FONT></FONT>
        </P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Conseiller ou
        Consultant : désigne la personne Physique disposant d’un éventail
        de droits d’accès spécifiques lui permettant de créer plusieurs
        comptes entreprise en accès “lecture seule”. </FONT></FONT>
        </P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Administrateur
        Entreprise&nbsp;: désigne la personne responsable de l’utilisation
        du Service par l’Entité Tierce dans le cas d’une Licence Tiers
        pour sa propre entreprise.</FONT></FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Responsable Cosme et
        / ou Responsable Groupe : désigne la personne responsable de
        l’utilisation du Service par un ou plusieurs Administrateurs
        Entreprise, Utilisateurs Entreprise ou toute Entité Tierce dans le
        cas d’une Licence Tiers</FONT></FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Super Administrateur
        Local : Personne Physique et/ou morale disposant d’une liste de
        droits d’accès lui autorisant à gérer les utilisateurs et
        l’utilisation de la plateforme selon une limitation géographique
        où en fonction d’un périmètre de droits d’accès défini par
        une licence.</FONT></FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in; line-height: 100%">
        <FONT COLOR="#000000"><FONT FACE="Arial, serif">Super Administrateur
        : Personne Physique et/ou morale disposant du plus large éventail
        d’accès et de droits de modifications à la plateforme.</FONT></FONT></P>
    </UL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=3>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Objet
        de la plateforme DIGIPILOTE</B></FONT></P>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">CCI
    France met à disposition des Internautes une plateforme de service
    logiciel destinée aux entreprises dénommée «&nbsp;DIGIPILOTE&nbsp;»
    (ci-après «&nbsp;la Plateforme&nbsp;») ayant pour objet de&nbsp;:</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Proposer
        aux Utilisateurs de mesurer et de suivre l’évolution de ses
        différentes activités de transformation dans les différents
        services de son entreprise.</FONT></P>
    </UL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Et
    notamment les activités concernant la transformation digitale de
    l’entreprise à travers l’analyse, la mesure, le suivi et
    l’évolution de la maturité digitale d’une entreprise, d’un
    groupe d’entreprises tierces ou d’une unité au sein d’une
    entreprise. En fonction des résultats issus des questionnaires
    proposés aux Utilisateurs, la plateforme suggère des leviers
    d’action permettant aux Utilisateurs de composer et de complémenter
    leur propre plan d’action pour la transformation digitale de leur
    entreprise.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">La
        plateforme permet également de monitorer les activités de
        transformation avec la possibilité de se comparer dans un cadre
        statistique anonyme récolté auprès de la communauté des
        Utilisateurs.</FONT></P>
    </UL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Dans
    ce cadre, l’Utilisateur accepte de partager les résultats des
    scores obtenus à l’issue de ses réponses aux différents
    questionnaires.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Les
    scores sont partagés de manière anonymisées sur la Plateforme.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Les
    résultats de scores de chaque Utilisateur et leur évolution sont
    utilisés en tant que données statistiques. Ces données
    statistiques sont un des services essentiels offerts à la communauté
    des Utilisateurs et un des services principaux disponibles pour la
    communauté des Utilisateurs.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Les
    modalités d’accès aux statistiques sectorielles pour tel ou tel
    Utilisateur sont prévues et conditionnées par les Conditions
    Générales de Vente. <!-- A vérifier si CGV ou CGU et CGV  --></FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=4>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Services
        proposés par Digipilote</B></FONT></P>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Un
    compte Utilisateur donne accès à&nbsp;des espaces numériques
    personnalisés comprenant un tableau de bord, un espace
    «&nbsp;Entreprises&nbsp;», des questionnaires, une bibliothèque de
    contenus essentiellement sous forme de texte, des statistiques, une
    base de connaissances, un back office.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%">
    <BR>
    </P>
    <UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Le
        tableau de bord permet de visualiser et de gérer son portefeuille
        d’entreprises clientes. Les services disponibles pour gérer ses
        données d’entreprises sont&nbsp;: </FONT>
        </P>
        <UL>
            <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Une
            fiche entreprise </FONT>
            </P>
            <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">des
            questionnaires à sélectionner selon le cosme de destination de
            l’accompagnement proposé par l’Utilisateur, </FONT>
            </P>
            <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">un
            rapport synthétique des résultats issus des questionnaires, par
            sujet, avec la possibilité pour l’Utilisateur d’ajouter des
            commentaires</FONT></P>
            <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">une
            roadmap générée automatiquement à l’issu des questionnaires,
            composée de&nbsp;:</FONT></P>
            <UL>
                <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%">
                <FONT FACE="Arial, serif">solutions d’accompagnement proposées
                soit de manière automatique, soit personnalisées,</FONT></P>
                <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%">
                <FONT FACE="Arial, serif">un planning de réalisation qui sera
                paramétré selon les besoins de l’entreprise cible.</FONT></P>
            </UL>
        </UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’espace
        Entreprise permet de créer une nouvelle entreprise et d’affecter
        un questionnaire.</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Un
        questionnaire est une liste de questions élaborées à partir d’un
        référentiel défini pour un cosme spécifique. Tous les
        questionnaires sont construits sur une base identique (Thème,
        Sujets) afin de permettre la comparabilité.</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">La
        Bibliothèque permet d’avoir accès aux questionnaires et de
        proposer des ajouts/modifications à l’administrateur du
        questionnaire.</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’espace
        Statistiques permet de visualiser le positionnement du portefeuille
        d’entreprises par rapport à la concurrence selon différents
        critères de sélection&nbsp;: géographique, secteur d’activité,
        taille d’entreprise, thème de questionnaire, et sujet de
        questionnaire. </FONT>
        </P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’espace
        Connaissance comprend une FAQ (Frequently Ask Questions), des
        connaissances métiers, des témoignages, tutoriels et de la veille
        paramétrable selon le profil de l’Utilisateur.</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Le
        Back office est accessible selon le profil de l’Utilisateur (voir
        ci-dessous)</FONT></P>
    </UL>
    <P ALIGN=JUSTIFY STYLE="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%">
    <BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%">
    <FONT FACE="Arial, serif">Les différents profils Utilisateurs et
    leurs droits affectés sont&nbsp;: </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-left: 0.25in; margin-bottom: 0in; line-height: 100%">
    <BR>
    </P>
    <UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Conseiller
        et/ou consultant&nbsp;: Utilisateur qui a demandé l’accès aux
        services de la plate-forme Digipilote via l’adresse
        www.digipilote.eu. Il dispose de droits d’accès aux espaces
        ci-dessus mentionnés selon les choix d’options exprimés lors de
        la demande d’ouverture de compte.</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in"><FONT FACE="Arial, serif">Administrateur
        Entreprise : désigné par l’entreprise cliente de Digipilote lors
        de la  création de l’Espace Entreprise défini selon les CGV.
        L’Administrateur Entreprise à accès en lecture/écriture des
        questionnaires, il peut proposer des améliorations aux
        questionnaires, accède aux statistiques générales, peut créer un
        utilisateur et partager des données présentes dans son espace.</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in"><FONT FACE="Arial, serif">Utilisateur
        Entreprise : désigné par l’Administrateur entreprise, il dispose
        des droits définis pour l’Administrateur Entreprise, excepté
        l’accès aux statistiques et la création d’un utilisateur.</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Responsable
        Cosme et/ou Groupe&nbsp;: désigné par l’entreprise cliente de
        Digipilote lors de la  création de l’Espace Entreprise défini
        selon les CGV. L’Administrateur Entreprise à accès en
        lecture/écriture des questionnaires, il peut proposer des
        améliorations aux questionnaires, accède aux statistiques
        générales, peut créer un utilisateur et partager des données
        présentes dans son espace.</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Super
        administrateur local&nbsp;: désigné par l’Entreprise cliente
        d’une licence Groupe (voir CGV), le Super Administrateur a accès
        à l’ensemble des fonctionnalités ci-dessus exposées ainsi
        qu’aux fonctionnalités suivantes&nbsp;: création de Units, de
        branches, de Pays, de Groupe, validation des améliorations de
        questionnaires, création de secteurs, création de services,
        création de taille d’entreprise.</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Super
        Administrateur&nbsp;: désigné par CCI France pour gérer
        l’ensemble des comptes Utilisateurs et des Services Digipilote, le
        Super Administrateur a accès à l’ensemble des fonctionnalités
        ci-dessus exposées ainsi qu’aux fonctionnalités suivantes&nbsp;:
        création de Units, de branches, de Pays, de Groupe, validation des
        améliorations de questionnaires, création de secteurs, création
        de services, création de taille d’entreprise.</FONT></P>
    </UL>
    <P ALIGN=JUSTIFY STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%">
    <BR>
    </P>
    <OL START=5>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Accès
        à la Plateforme</B></FONT></P>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’accès
    à la Plateforme est ouvert aux personnes physiques et morales. Sur
    le plan technique, l’accès à la Plateforme est possible 24h sur
    24 et 7 jours sur 7, sous réserve de la survenance d’un cas de
    force majeure, des pannes éventuelles ou de toute opération de
    maintenance nécessaire au bon fonctionnement de la Plateforme.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">En
    tout état de cause, l’Utilisateur reconnaît disposer de la
    compétence et des moyens nécessaires pour accéder et utiliser la
    Plateforme. A ce titre, l’Utilisateur doit disposer d’un
    ordinateur ou de tout autre appareil bénéficiant d’une connexion
    Internet dont les paramétrages permettent bien évidemment un bon
    fonctionnement de la Plateforme. L’Utilisateur déclare en
    connaître les risques et les accepter. L’Utilisateur reconnait
    notamment que les informations qui y transitent ou y sont stockées
    peuvent être interceptées ou altérées indépendamment de la
    volonté de CCI France. Il est cependant fortement recommandé à
    l’Utilisateur de prendre toutes les précautions nécessaires afin
    de se prémunir contre les effets de la piraterie, notamment en
    adoptant une configuration informatique sécurisée et adaptée, par
    la mise en place d’un logiciel de détection de virus régulièrement
    mis à jour. Si CCI France a connaissance, de quelque manière que ce
    soit, d’une utilisation frauduleuse de ses services, elle se
    réserve le droit de mettre fin aux présentes Conditions Générales
    d’Utilisation, sans avertissement préalable ni indemnité, ainsi
    que de refuser l’accès futur à tout ou partie de ses services à
    la personne qui a détourné ou frauduleusement utilisé ses
    services.</FONT></P>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=6>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Acceptation
        des CGU et accès aux Services</B></FONT></P>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in"><FONT FACE="Arial, serif">Les
    CGU de la Plateforme s'appliquent, sans restriction ni réserve, à
    tous les services proposés sur la Plateforme.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in"><FONT FACE="Arial, serif">Les
    CGU de la Plateforme sont additionnelles, le cas échéant, aux
    Conditions Particulières et/ou aux Conditions Générales de Vente
    applicables à certaines fonctionnalités des services de la
    Plateforme et à leur utilisation.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in"><FONT FACE="Arial, serif">L’Utilisateur
    reconnaît avoir pris connaissance des présentes Conditions
    Générales d’Utilisation avant toute utilisation des Services et
    s’engage à les respecter.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’accès
    à la Plateforme et à ses services est conditionné par
    l’acceptation sans restriction ni réserve des présentes CGU par
    l’Utilisateur. L’Utilisateur reconnait que l’acceptation des
    présentes CGU entraine l’autorisation d’utilisation des scores
    issus de ses réponses aux questionnaires qu’il aura remplis à des
    fins statistiques. Ces statistiques étant mises à disposition de la
    communauté des Utilisateurs sous la forme et dans le niveau de
    détails que CCI France aura décidé dans le meilleur intérêt et
    pour le bon fonctionnement de la Plateforme et en fonction du type de
    licences ou de droits d’accès de tel ou tel Utilisateur selon son
    profil.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in"><FONT FACE="Arial, serif">Pour
    valider la création de son compte, l’Utilisateur est tenu de
    cocher la case par laquelle il reconnaît être parfaitement informé
    des présentes Conditions Générales d’Utilisation et les
    accepter. A défaut, le compte n’est pas créé.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in"><FONT FACE="Arial, serif">Les
    présentes Conditions Générales d’Utilisation peuvent être
    modifiées à tout moment. Ces modifications sont opposables à
    l’Utilisateur dès leur mise en ligne sur la Plateforme. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in"><FONT FACE="Arial, serif">La
    date de mise à jour est mentionnée. L’Utilisateur est donc invité
    à consulter régulièrement la dernière version mise à jour.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=7>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Condition
        d’utilisation de <A HREF="http://www.digipilote.eu">www.digipilote.eu</A>
        et des services associés</B></FONT></P>
    </OL>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Lors
    de la première connexion sur www.digipilote.eu, l’Utilisateur
    procède à la création d’un compte personnel qui lui permet
    d’accéder à son « Profil » et de se connecter à son espace
    depuis tout type de terminal.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Toute
    saisie d’informations fausses, inexactes, illégitimes,
    non-actualisées ou incomplètes ou d’un nom d’Utilisateur
    contenant des termes, injurieux, diffamants, violents, obscènes ou
    plus généralement des termes contraires à l’ordre public et aux
    bonnes mœurs, pourra donner lieu à la suspension ou à la fermeture
    du Compte Utilisateur par CCI France.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    s’engage à garder ses identifiants et mot-de-passe strictement
    confidentiels et à ne pas les communiquer à des tiers, afin
    d'éviter autant que possible tout risque d'intrusion ou d’usurpation
    de son Compte Utilisateur. CCI France ne saurait être tenue
    responsable de toute utilisation du Compte Utilisateur par un tiers
    qui aurait eu accès aux identifiants et au mot-de-passe de
    l’Utilisateur de quelque manière que ce soit.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    s'engage à ne pas créer ou utiliser d'autres comptes que celui
    initialement créé, que ce soit sous sa propre identité ou celle de
    tiers sauf autorisation préalable expresse de CCI France. Le fait de
    créer ou utiliser de nouveaux comptes sous sa propre identité ou
    celle de tiers sans avoir obtenu l'autorisation préalable expresse
    de CCI France pourra entraîner la suspension ou la suppression
    immédiate des comptes de l’Utilisateur.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">A
    l’issue de la première connexion sur son compte, l’Utilisateur
    recevra un courrier électronique de confirmation de création du
    Compte-Utilisateur.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Le
    Compte Utilisateur est personnel et permet à l’Utilisateur
    d’accéder aux Services dans le cadre d’une Licence Utilisateur
    gratuite ou payante.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’utilisateur
    est seul responsable du respect des règles, règlement intérieur,
    charte informatique en vigueur auprès de son entité ou dans les
    locaux où se déroule l’Utilisation de la Plateforme et sera seul
    responsable des dommages de toute nature qu’un non-respect
    entraineraient.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Dans
    le cas d’une licence Tiers, l'Administrateur est responsable de
    l'utilisation de la Plateforme et de ses Services, et veille à ce
    que ces Utilisateurs se conforment aux règles internes applicables
    et en vigueur au sein de l’entité qui emploie ces Utilisateurs et
    Administrateurs.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    ou l'Administrateur (lorsque l'Utilisateur fait partie du personnel
    de l'Administrateur) est seul responsable des modalités d’accès
    aux Services, du contenu, des informations et données transmis,
    diffusés ou collectés, de leur exploitation et de leur mise à jour
    dans le cadre de l’utilisation des Services.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    s’engage à accéder et utiliser les Services conformément aux
    dispositions des présentes CGU, et ce, dans le respect des
    dispositions légales et réglementaires applicables.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    s'engage à ne pas accéder aux Services par un moyen quelconque
    autre que celui fourni par CCI France, sauf autorisation préalable
    expresse de CCI France par contrat spécifique.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    reconnaît que l’accès et l’utilisation des Services est réservé
    à un usage personnel uniquement dans le cadre d’une utilisation de
    la Plateforme, et s’interdit tout usage commercial des Services
    impliquant toutes formes de commercialisation des Services en tant
    que tel.</FONT></P>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=8>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Propriété
        Intellectuelle</B></FONT></P>
    </OL>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><A NAME="_GoBack"></A>
    <FONT FACE="Arial, serif">L’ensemble des droits de propriété
    intellectuelle portant sur tous les éléments composant la
    Plateforme, notamment les marques, logos, slogans, photographies,
    programmes, données, bases de données, images animées ou non,
    sons, dessins, graphismes, vidéos, textes, fiches de présentation
    des Services, noms des Services, et visuels associés, autres que
    ceux relatifs à des liens vers des sites tiers, sont de la propriété
    de CCI France. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">CCI
    France est donc seul titulaire de l'ensemble des droits de propriété
    intellectuelle afférents aux services, à la Plateforme, à son
    contenu ainsi qu’aux bases de données assurant son fonctionnement,
    et l'utilisation de la Plateforme et de ses Services ne donne aux
    Utilisateurs aucun droit sur l'un quelconque de ces éléments, sous
    réserve des droits afférents aux contenus fournis par les
    Utilisateurs eux-mêmes ou par des tiers.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L'acceptation
    des présentes CGU vaut reconnaissance par les Utilisateurs des
    droits de propriété intellectuelle de CCI France ci-dessus énumérés
    et l'engagement de les respecter.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL>
        <OL>
            <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%">–
            <FONT FACE="Arial, serif">Licence d’utilisation </FONT>
            </P>
        </OL>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">CCI
    France concède à l’Utilisateur une licence non exclusive,
    personnelle et non transférable l'autorisant à utiliser la
    Plateforme et les services, ainsi que les données et contenus qu'ils
    contiennent, pour son usage à titre strictement personnel,
    conformément aux présentes CGU et, en particulier, conformément à
    la finalité de la Plateforme et de ses Services.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Toute
    autre exploitation ou utilisation de la Plateforme, des Services, de
    leurs contenus et notamment des données qu'ils contiennent est
    exclue du domaine de la présente Licence et ne pourra être
    effectuée qu'après avoir obtenu l'autorisation écrite et préalable
    de CCI France. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">En
    particulier, toute extraction ou réutilisation au-delà de l'usage
    normal de la Plateforme, des données contenues dans la Plateforme
    et/ou les Services est strictement interdite et soumise à
    l'autorisation expresse et préalable de CCI France.</FONT></P>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL>
        <OL START=2>
            <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Contenu
            généré par l’Utilisateur</FONT></P>
        </OL>
    </OL>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Afin
    de permettre la fourniture des Services et conformément à l’objet
    de la Plateforme DigiPilote, l’Utilisateur concède à CCI France,
    au réseau des CCI de France et à la communauté des Utilisateurs de
    la Plateforme une licence non exclusive et à titre gratuit,
    d'utilisation des contenus et des données que l’Utilisateur est
    amené à intégrer et/ou mettre en ligne au sein de la Plateforme
    pour la génération des informations statistiques qu’il contribue
    à créer par son utilisation de la Plateforme. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    garantit que les données, informations ou contenu(s) en cause sont
    conformes à la loi et ne portent pas atteinte aux droits d'autrui.
    Il garantit avoir obtenu l’ensemble des autorisations des
    titulaires de droits ou ayants droit sur les contenus d’autrui
    qu’il aurait incorporé au sein des contenus et des données
    intégrés et/ou mis en ligne au sein de la Plateforme.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">En
    publiant ses données, informations et/ou contenus sur la Plateforme,
    chaque Utilisateur accepte de partager les résultats des scores
    obtenus à l’issue de ses réponses aux différents questionnaires.
    Les scores sont partagés de manière anonymisées sur la Plateforme.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    reconnait et accepte que les résultats de ses scores et leur
    évolution soient utilisés en tant que données statistiques dans la
    mesure où ces données statistiques sont un des services essentiels
    offerts à la communauté des Utilisateurs et un des services
    principaux disponibles pour la communauté des Utilisateurs. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Aux
    seules fins d'exécution du Service, l’Utilisateur autorise CCI
    France à reproduire, représenter, adapter et traduire le contenu et
    les données fournies par lui via la Plateforme, dans les conditions
    ci-après définies :</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">CCI
        France est autorisée à reproduire tout ou partie du contenu fourni
        par l’Utilisateur sur tout support d'enregistrement numérique,
        actuel ou futur, et notamment, sans que cette liste soit limitative,
        sur tout serveur, disque dur, amovible ou non, carte mémoire, ou
        tout autre support équivalent, en tout format et par tout procédé
        connu et inconnu à ce jour, dans la mesure nécessaire à toute
        opération de stockage, sauvegarde, transmission ou téléchargement
        impliquée par le fonctionnement de la Plateforme et la fourniture
        des Services.</FONT></P>
    </UL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">CCI
        France est autorisée à adapter et traduire le contenu des
        Utilisateurs, ainsi qu'à reproduire ces adaptations sur tout
        support numérique, actuel ou futur, stipulé ci-dessus, dans le but
        de fournir les Services, notamment en différentes langues. </FONT>
        </P>
    </UL>
    <P STYLE="margin-left: 0.5in; margin-bottom: 0.11in"><BR><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Cette
    autorisation a pour but de permettre à CCI France via la Plateforme,
    de monitorer les activités de transformation avec la possibilité
    pour chaque Utilisateur de se comparer dans un cadre statistique
    anonyme récolté auprès de la communauté des Utilisateurs. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Cette
    licence est consentie pour le monde entier et pour la durée
    d’utilisation de la Plateforme par CCI France, le réseau des CCI
    de France et la communauté des Utilisateurs.  </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Dans
    l'hypothèse où une atteinte manifeste serait portée à des droits
    de propriété intellectuelle sur la Plateforme, la personne ayant
    intérêt à ce que son droit soit préservé est invitée à
    signaler CCI FranceFrance., via l’adresse XXX.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=9>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Traitement
        de Données Personnelles</B></FONT></P>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Conformément
    au Règlement UE n°2016/679 du 27 avril 2016 dit «&nbsp;RGPD&nbsp;»
    et à la loi n° 78-17 du 6 janvier 1978 relative à l'informatique,
    aux fichiers et aux libertés modifiée, dans le cadre de la mission
    de service public confiée aux CCI de France et à CCI France, en
    application du code du commerce et pour le compte de ces entités,
    CCI France enregistre les données concernant les Utilisateurs et
    leurs usages pour assurer le bon fonctionnement de la Plateforme, la
    gestion du compte Utilisateur ainsi que la qualité des services. CCI
    France ou le réseau des CCI de France peuvent être amenés à
    utiliser ces informations pour améliorer leurs services
    d’accompagnement au développement économique des entreprises et
    des territoires. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Les
    Données Personnelles dont CCI France a besoin au titre des usages
    ci-dessous défini sont les suivantes : nom, prénom, numéro de
    téléphone et adresse mail de l’Utilisateur.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Ainsi,
    CCI France s’engage à n’utiliser les Données Personnelles
    <!-- Ajouter la fonctionnalité de chat.  -->que pour les usages
    suivants&nbsp;:</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">créer,
        gérer et administrer les Comptes Utilisateurs&nbsp;;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">héberger
        le Contenu Utilisateur&nbsp;;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">identifier
        les Utilisateurs et permettre l’Utilisation de la Plateforme
        Digipilote&nbsp;; </FONT>
        </P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">sécuriser
        l’accès au Contenu Utilisateur&nbsp;;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">permettre
        à l’utilisateur d’accéder au module de discussion en ligne&nbsp;;
        </FONT>
        </P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">mettre
        l’utilisateur en contact avec un tiers de confiance offrant des
        services non directement par le réseau des CCI et ou des
        partenaires du réseau des CCI dans le cas où l’utilisateurs
        l’aurait demandé et se faisant aura accordé l’autorisation de
        transmettre ses données personnelles à CCI France. </FONT>
        </P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">notifier
        aux Utilisateurs toute modification des fonctionnalités de la
        Plateforme ou modifications des CGU&nbsp;;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">procéder
        à des analyses statistiques anonymisées portant d’une part sur
        l’utilisation des services afin d’en améliorer les accès et
        les utilisations&nbsp;; et d’autre part sur les données
        collectées permettant d’alimenter la partie benchmark de
        Digipilote&nbsp;;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Procéder
        à une exploitation commerciale des statistiques anonymisées&nbsp;;
        </FONT>
        </P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">répondre
        aux sollicitations des Utilisateurs.</FONT></P>
    </UL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Durée
    de conservation : CCI France conserve les Données Personnelles
    pendant la durée d’activation du Compte Utilisateur. A compter de
    la désactivation du Compte Utilisateur, CCI France peut conserver
    les Données Personnelles pour une durée maximale de trois (3) ans,
    ces données étant communicables aux autorités habilitées pendant
    une durée d’un (1) an :</FONT></P>
    <UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">à
        compter de la création du contenu pour les informations relatives
        aux données de connexion de l’Utilisateur (par exemple,
        identifiant d’accès au serveur, date et heure de connexion) ; ou,</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">à
        compter de la clôture du compte pour les informations relatives à
        l’identité et les coordonnées de l’Utilisateur.</FONT></P>
    </UL>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">CCI
    France peut, le cas échéant, conserver les Données Personnelles
    sous une forme anonymisée.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Sécurité
    : CCI France attache la plus grande importance à la confidentialité
    des Données Personnelles des Utilisateurs et met en œuvre les
    mesures techniques et organisationnelles nécessaires à leur
    sécurisation, étant précisé que le réseau Internet n’est pas
    un environnement complètement sécurisé et que CCI France ne peut
    garantir la parfaite sécurité de la transmission ou du stockage des
    Données Personnelles sur ce réseau.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Destinataires
    : Les Données Personnelles fournies lors de son inscription par
    l’Utilisateur sont destinées à être utilisées uniquement par
    CCI France, le réseau des CCI de France  et/ou toute entité
    affiliée contrôlée par CCI France sous contrôle commun avec les
    CCI de France. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    est informé que les Données Personnelles afférentes à la création
    du Compte Utilisateur (nom, prénom, numéro de téléphone et
    adresse de courrier électronique de l’Utilisateur) peuvent être
    communiquées à l’Administrateur pour :</FONT></P>
    <UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">la
        gestion administrative, comptable ou financière des abonnements à
        la Plateforme&nbsp;;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">le
        déploiement de la Plateforme au sein de la structure du titulaire
        d’une Licence Tiers&nbsp;;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">la
        création d’Utilisations de de la Plateforme.</FONT></P>
    </UL>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    est informé que CCI France peut sous-traiter tout ou partie des
    opérations de traitement de Données Personnelles nécessaires à la
    réalisation des Services et des Usages. Ces prestataires ont
    l’obligation de traiter les Données Personnelles conformément à
    la Règlementation Applicable et présentent les garanties
    appropriées en matière de sécurité.</FONT></P>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Droits
    des Utilisateurs : Conformément aux dispositions du Règlement UE
    n°2016/679 du 27 avril 2016 dit «&nbsp;RGPD&nbsp;» et de la loi n°
    78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et
    aux libertés modifiée (notamment les articles 15 à 22 du RGPD),
    l’Utilisateur pourra à tout moment exercer son droit de demander à
    CCI France la désactivation du Compte Utilisateur, l'accès à ses
    Données Personnelles, la rectification ou l'effacement de celles-ci,
    ou une limitation du traitement relatif à l’Utilisateur, ou du
    droit de s'opposer à la réception de la newsletter et/ou de
    sollicitations commerciales de CCI France ou de ses partenaires et du
    droit à la portabilité des Données Personnelles. L’Utilisateur
    peut exercer ses droits, soit en cliquant le lien de désinscription
    contenu dans chaque notification reçue par courrier électronique,
    soit en notifiant CCI France de son intention par courrier
    électronique adressé à dpo@ccifrance.fr</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Afin
    de préserver la sécurité et la confidentialité des Données
    Personnelles, CCI France se réserve le droit de demander aux
    Utilisateurs une preuve de leur identité afin d’en vérifier
    l’exactitude, préalablement à l’exercice des droits listés
    ci-dessus ; étant précisé que cette copie sera supprimée trente
    (30) jours après sa réception par CCI France.<!-- Est-ce une obligation ?
     --><!-- Non, on peut supprimer si besoin.  --><!-- A conserver --></FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">En
    cas de demandes infondées ou excessives, notamment en raison de leur
    caractère répétitif, CCI France se réserve le droit de facturer
    ou de ne pas donner suite à toute demande d’un Utilisateur
    postérieure à une demande dûment satisfaite par CCI France et
    portant sur le même objet. CCI France a désigné un Délégué à
    la Protection des Données dont l’adresse de contact est
    <A HREF="mailto:dpo@ccifrance.fr">dpo@ccifrance.fr</A>. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Il
    est de la responsabilité de l’Utilisateur, lorsqu’il intègre
    des données personnelles de tiers dans son espace, de garantir le
    droit d’accès et de rectification aux données personnelles des
    tiers concernés, conformément à la Réglementation applicable. En
    cas de refus de l’Utilisateur d’exercer ce droit, CCI France
    s’engage à exercer ce droit à la demande de tiers, après en
    avoir informé l’Utilisateur.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=10>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Cookies</B></FONT></P>
    </OL>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    est informé que pour fluidifier l’accès et permettre
    l’utilisation de la Plateforme et des Services par l’Utilisateur,
    des cookies peuvent être installés sur son Terminal.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Un
    cookie est un fichier texte non exécutable installé sur le Terminal
    de l’Utilisateur lors de l’utilisation du Logiciel.de la
    Plate-forme</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">La
    liste des cookies et leurs finalités sont disponibles dans la
    Politique de Confidentialité DIGIPILOTE: (Ajouter
    URL)www.digipilote.eu<!-- Nous disposons d’une politique de confidentialité. Celle de CCI Store pourrait être réemployée en l’espèce.
    https://www.ccistore.fr/PrivacyPolicy --></FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">De
    plus, l’Utilisateur est informé et reconnaît que l’installation
    de cookies permet à DIGIPILOTE de lui proposer du contenu
    personnalisé (e.g. historique de ses connexions et utilisations des
    Services).</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    pourra à tout moment retirer son consentement à l’installation de
    cookies en configurant les paramètres de connexion de son Terminal.
    L’utilisateur peut à tout moment désactiver les cookies.<!-- à modifier en fonction de ce qui est applicable pour DigiPilote en termes de cookies --></FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    déclare et reconnaît que le refus de l’installation des cookies
    ou leur suppression pourrait altérer l’accès aux Services ou leur
    fourniture.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=11>
        <LI><P STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Confidentialité</B></FONT></P>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Les
    Parties s’engagent à maintenir confidentielles les informations
    confidentielles de toute nature échangées, collectées ou nées à
    l’occasion de l'utilisation de la Plateforme et ce, pendant toute
    la durée de la Licence Utilisateur et pendant une durée de cinq (5)
    ans à compter de la fin de la Licence Utilisateur, pour quelque
    cause que ce soit. A cet effet, les Parties s’interdisent de
    communiquer à quelque titre que ce soit, sous quelque forme que ce
    soit et à quelque fin que ce soit (à l’exception d’une
    utilisation interne chez l’Administrateur) l'ensemble de ces
    informations et s'engagent à faire respecter cette obligation par
    l'ensemble de ses dirigeants, préposés, agents et éventuels
    sous-traitants.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Les
    Utilisateurs accédant à la Plateforme dans le cadre d’une
    Utilisation DIGIPILOTE via une Licence Tiers s’engagent à
    respecter les règles internes de confidentialité en vigueur qui
    leur seraient notifiées le cas échéant par l’Administrateur. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Les
    obligations des Parties aux termes du présent article ne s’étendent
    pas aux informations confidentielles pour lesquelles la Partie qui
    les reçoit peut prouver :</FONT></P>
    <UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">qu’elle
        les a divulguées après l’obtention préalable de l’autorisation
        écrite de l’autre Partie ou que la divulgation a été réalisée
        par ladite autre Partie ;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">qu’elles
        étaient accessibles au public au moment de leur communication par
        l’autre Partie, ou qu’elles y sont tombées après cette
        communication sans faute de sa part;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">qu’elles
        ont été reçues, de manière licite, d’un tiers soumis à aucune
        obligation de confidentialité;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">qu’à
        la date de leur communication par l’autre Partie, elle était déjà
        en possession de celle-ci;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">que
        leur divulgation a été imposée par application d’une
        disposition légale ou réglementaire impérative, d’une décision
        de justice définitive rendue par une juridiction compétente. La
        Partie soumise à une telle obligation de divulgation se doit, dans
        la mesure du possible, d’en avertir préalablement l’autre
        Partie, et, le cas échéant, de demander la mise en œuvre de
        toutes les mesures ou procédures de protection de la
        confidentialité applicables en l’espèce.</FONT></P>
    </UL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Les
    exceptions précitées ne sont pas cumulatives.</FONT></P>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=12>
        <LI><P STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Garantie</B></FONT></P>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Dans
    la mesure autorisée par la loi applicable, la Plateforme et les
    Services sont fournis « en l’état », ce que l’Utilisateur
    reconnaît et accepte.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Du
    fait de la nature même du réseau Internet et des réseaux de
    téléphonie mobile, CCI France ne saurait garantir à l’Utilisateur
    la disponibilité continue et permanente de l’accès aux Services.
    CCI France s’engage à ce titre à faire ses meilleurs efforts pour
    fournir à l’Utilisateur le niveau de service et d’accessibilité
    à la plateforme suivant : disponibilité 24h/24 et 7j/7 (à 98%) ;</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">CCI
    France ne garantit pas que la Plateforme et/ou les Services soient
    exempts d'anomalies, d’erreurs ou de bugs et que leur
    fonctionnement soit ininterrompu. En conséquence, il est rappelé à
    l’Utilisateur qu'il lui appartient de prendre toute mesure
    appropriée (notamment sauvegardes régulières) pour minimiser les
    conséquences dommageables liées notamment à une possible
    interruption d'exploitation ou à une possible perte de données
    générée par la Plate-forme du fait de son utilisation.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">CCI
    France ne garantit pas la véracité du Contenu Utilisateur et/ou de
    toutes données et informations stockées, hébergées, affichées,
    diffusées et transmises durant une Utilisation DIGIPILOTE, leur
    actualité et/ou leur caractère exhaustif.</FONT></P>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=13>
        <LI><P STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Responsabilité</B></FONT></P>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">CCI
    France ne saurait être tenue pour responsable, vis-à-vis de
    l’Utilisateur, des dommages de toute nature, directs ou indirects,
    résultant de l’utilisation de la Plateforme, ni de l’impossibilité
    pour d’y accéder pour quelque raison que ce soit. CCI France ne
    saurait également être tenue pour responsable, vis-à-vis de
    l’Utilisateur, des dommages de toute nature, directs ou indirects,
    résultant de l’utilisation d’un Service, ni de l’impossibilité
    d’y accéder pour quelque raison que ce soit. En tout état de
    cause, l’Utilisateur reconnaît que CCI France se réserve le droit
    de modifier, d’interrompre temporairement ou de façon permanente
    tout ou partie des services. CCI France ne pourra voir sa
    responsabilité recherchée en cas d’indisponibilité temporaire,
    partielle ou totale de la Plateforme, notamment en cas de
    maintenance, en cas d’incident technique et, plus généralement,
    en cas d’évènement extérieur à son contrôle. </FONT>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">CCI
    France sera tenue responsable uniquement des dommages directs et
    prévisibles, causés par un manquement de CCI France aux obligations
    lui incombant au titre des présentes.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><A NAME="_Hlk34924821"></A>
    <FONT FACE="Arial, serif">L’Utilisateur s'engage à garantir, à la
    première demande, y compris en cas de décision de justice non
    définitive, à indemniser et dédommager CCI France de tous dommages
    directs que CCI France pourrait subir si sa responsabilité se
    trouvait engagée par un tiers, du fait d'une revendication, d'une
    action ou d'une plainte liée au Contenu Utilisateur produit par
    l’Utilisateur ou informations hébergées, affichées ou diffusées
    via les Services par l’Utilisateur ou consécutive à une
    utilisation des Services en violation des CGU.</FONT></P>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=14>
        <LI><P STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Force
        majeure</B></FONT></P>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">En
    cas de force majeure au sens de l’article 1218 du Code civil, les
    obligations des Parties, au titre des CGU, seront suspendues pendant
    toute la durée du cas de force majeure et reprendront à compter de
    la cessation de ce dernier.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L'absence
    d'accès à la Plateforme et/ou l’inexécution des Services
    imputable à un cas de force majeure ne pourra faire l’objet
    d’aucun recours. En cas de survenance d’un tel événement
    empêchant CCI France de respecter ses engagements, cette dernière
    s’engage à en informer l’Utilisateur dans les plus brefs délais.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">CCI
    France et/ou l’Utilisateur pourront librement résilier les CGU en
    cas de persistance du cas de force majeure au-delà de soixante (60)
    jours à compter de la notification à l’autre Partie de la
    survenance du cas de force majeure, sans qu’aucune des Parties ne
    puisse prétendre à l’octroi de dommages et intérêts. </FONT>
    </P>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=15>
        <LI><P STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Résiliation</B></FONT></P>
    </OL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">L’Utilisateur
    peut cesser d'utiliser la Plateforme et/ou les Services à tout
    moment. Il appartient à l’Utilisateur de notifier CCI France de
    son intention par courrier électronique adressé à  (adresse email)
    pour résilier le Contrat, à la suite de quoi CCI France adressera
    par courrier électronique un accusé de confirmation de ladite
    résiliation.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Il
    est expressément convenu entre les Parties que les CGU pourront être
    immédiatement résiliées par CCI France et le Compte Utilisateur
    supprimé de plein droit par cette dernière, sans mise en demeure et
    sans préjudice de toute action par CCI France en dommages et
    intérêts :</FONT></P>
    <UL>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">en
        cas de manquement de l’Utilisateur à l’un quelconque de ses
        engagements au titre des présentes CGU;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">en
        cas de violation de l’article 8 « Propriété intellectuelle » ;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">en
        cas d’utilisation de la Plateforme et/ou des Services non conforme
        aux présentes CGU et/ou à la Licence Utilisateur ;</FONT></P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">en
        cas de résiliation de la Licence Utilisateur ; </FONT>
        </P>
        <LI><P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">en
        cas d’utilisation  de la Plateforme et/ou des Services non
        conforme aux lois et règlement applicables.</FONT></P>
    </UL>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Les
    Articles 8 « Propriété Intellectuelle », 9 « traitement des
    données personnelles », 11 « Confidentialité », 12 « Garantie
    », 13 « Responsabilité », continueront de s'appliquer à compter
    de la cessation ou la résiliation des CGU pour quelque cause que ce
    soit.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Le
    Compte Utilisateur sera supprimé et l’accès aux Services
    interrompu dans un délai de 30 jours à compte de la notification de
    résiliation envoyée par email à l’Utilisateur à l’adresse qui
    figure dans le Compte Utilisateur. </FONT>
    </P>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=16>
        <LI><P STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Modifications
        des Services et des CGU</B></FONT></P>
    </OL>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Les
    différents Services offerts par la plateforme Digipilote feront
    l’objet de mises à jour, modifications ou suppressions à tout
    moment compte tenu de la technologie et de la nature même de la
    Plateforme Digipilote ou de la volonté de CCI France de supprimer,
    prolonger ou modifier tel ou tel service.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">En
    conséquence, Les CGU pourront faire l’objet d’ajout/modifications
    par CCI France.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">Toute
    modification <!-- A adapter en fonction de ce qui est proposé par DigiPilote. -->des
    CGU portant sur les caractéristiques de la Plate-forme et/ou des
    Services sera communiquée à l’Utilisateur par l’affichage
    d’une pop-up lors d’une nouvelle connexion<!-- A valider avec Mika -->.
    L’Utilisateur Authentifié devra accepter les nouvelles CGU afin de
    pouvoir continuer à utiliser la plateforme.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif">En
    cas de refus, l’accès à la plateforme sera refusé suspendu pour
    à l’utilisateur, et CCI France pourra dans ce cas résilier
    l’accès à Digipilote pour l’Utilisateur défaillant si elle le
    juge nécessaire. Dans ce cas, l’utilisateur ne pourra exiger de
    remboursement de sa licence annuelle qui restera intégralement due..</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <OL START=17>
        <LI><P STYLE="margin-bottom: 0in; line-height: 100%"><FONT FACE="Arial, serif"><B>Loi
        applicable/attribution de juridiction</B></FONT></P>
    </OL>
    <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
    </P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in"><FONT FACE="Arial, serif">Les
    présentes Conditions Générales d’Utilisation sont régies,
    interprétées et appliquées conformément au droit français.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in"><FONT FACE="Arial, serif">Tout
    litige survenant concernant leur interprétation, leur modification
    ou leur exécution relèvera, à défaut d’accord amiable, de la
    compétence exclusive des juridictions françaises.</FONT></P>
    <P ALIGN=JUSTIFY STYLE="margin-bottom: 0.11in"><FONT FACE="Arial, serif">En
    cas de contestation liée à l’application et/ou interprétation
    des présentes Conditions Générales d’Utilisation, l’Utilisateur
    a la possibilité de recourir à une procédure de médiation
    conventionnelle ou à toute autre mode alternatif de règlement des
    différends. </FONT>
    </P>
    <DIV TYPE=FOOTER>
        <P ALIGN=CENTER STYLE="margin-top: 0.45in; margin-bottom: 0in; line-height: 100%">
        <P STYLE="margin-bottom: 0in; line-height: 100%"><BR>
        </P>
    </DIV>
    </BODY>
    </HTML>`

    constructor() {
    }

    ngOnInit() {
    }


}
