import { Injectable } from '@angular/core';

export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    label?: string;
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    roles?: string[],
    write?: boolean,
    cosme?: boolean,
    label?: string;
    children?: ChildrenItems[];
}

const MENUITEMS = [
    {
        state: 'dashboard',
        name: 'DASHBOARD',
        type: 'link',
        icon: 'fa fa-tachometer icons',
        roles: ['root', 'admin_cosme', 'admin_group', 'admin_branch', 'admin_unit', 'adviser'],
        cosme: true,
    },
    {
        state: 'company',
        name: 'COMPANIES',
        type: 'link',
        icon: 'fa fa-building icons',
        roles: ['root', 'admin_cosme', 'admin_group', 'admin_branch', 'admin_unit', 'adviser'],
        cosme: true,
    },
    {
        state: 'questionnaire',
        name: 'QUESTIONNAIRES',
        type: 'link',
        icon: 'fa fa-calendar-check-o icons',
        roles: ['root', 'admin_cosme', 'admin_group', 'admin_branch', 'admin_unit', 'adviser'],
        cosme: true,
    },
    {
        state: 'library',
        name: 'LIBRARY_MENU',
        type: 'link',
        icon: 'fa fa-book icons',
        roles: ['root', 'admin_cosme', 'admin_group', 'admin_branch', 'admin_unit', 'adviser'],
    },
    // Masquer le menu statistiques : https://support.cci.fr/front/ticket.form.php?id=17500
    //{
    //    state: 'statistics',
    //    name: 'Stastistiques',
    //    type: 'link',
    //    icon: 'fa fa-pie-chart icons',
    //    roles: ['root', 'admin_cosme', 'admin_group', 'admin_branch', 'admin_unit', 'adviser'],
    //    cosme: true,
    //},
    {
        state: 'knowledge',
        name: 'KNOWLEDGE',
        type: 'link',
        icon: 'fa fa-graduation-cap icons',
        roles: ['root', 'admin_cosme', 'admin_group', 'admin_branch', 'admin_unit', 'adviser'],
    },
    {
        state: '',
        name: 'Backoffice',
        type: 'external_link',
        icon: 'fa fa-sitemap icons',
        roles: ['root', 'admin_cosme', 'admin_group', 'admin_branch', 'admin_unit'],
    },

    // Client item.
    {
        state: 'dashboard-company',
        name: 'DASHBOARD',
        type: 'link',
        icon: 'fa fa-tachometer icons',
        roles: ['admin_company', 'company'],
        cosme: true,
    },
    {
        state: 'company-questionnaire',
        name: 'QUESTIONNAIRE',
        type: 'link',
        icon: 'fa fa-clipboard icons',
        roles: ['admin_company'],
        write: true,
        cosme: true,
    },
    {
        state: 'results',
        name: 'REPORT',
        type: 'link',
        icon: 'fa fa-bar-chart icons',
        roles: ['admin_company', 'company'],
        cosme: true,
    },
    {
        state: 'actions',
        name: 'ROADMAP',
        type: 'link',
        icon: 'fa fa-th-list icons',
        roles: ['admin_company', 'company'],
        cosme: true,
    },
    {
        state: 'departments',
        name: 'DEPARTMENTS',
        type: 'link',
        icon: 'fa fa-building',
        roles: ['admin_company'],
        write: true,
        cosme: true,
    },
    {
        state: 'company-users',
        name: 'USERS',
        type: 'link',
        icon: 'fa fa-users icons',
        roles: ['admin_company'],
        write: true,
        cosme: true,
    },
];

@Injectable()
export class MenuItems {
    getAll(role: string, cosmeId: string, hasWritePermission: boolean): Menu[] {
        const MENU: Menu[] = [];
        MENUITEMS.forEach((item: Menu) => {
            if (((!item.roles || (item.roles.findIndex(r => r === role) !== -1)) &&
                (!item.write || hasWritePermission) && (!item.cosme || cosmeId)) && cosmeId !== null) {
                MENU.push(item);
            }
        });
        return MENU;
    }

    add(menu: any) {
        MENUITEMS.push(menu);
    }
}

