import 'hammerjs';

import { AgmCoreModule } from '@agm/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SidebarModule } from 'ng-sidebar';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { Socket, SocketIoModule } from 'ngx-socket-io';
import { ToastrModule } from 'ngx-toastr';
import { TourNgBootstrapModule } from 'ngx-tour-ng-bootstrap';

import { RoutingModule } from './app-routing.module';
import { ChankyaAppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { ConfigModule } from './config/config.module';
import { TranslateService } from './config/services/translate.service';
import { ConfirmDialogComponent } from './confirm-dialog/components/confirm-dialog.component';
import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';
import { MenuItems } from './core/menu/menu-items/menu-items';
import { MenuToggleModule } from './core/menu/menu-toggle.module';
import { PageTitleService } from './core/page-title/page-title.service';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { SentryErrorHandler } from './error-handling/sentry-error-handler';
import { LoadingComponent } from './http-request-indicator/components/loading/loading.component';
import { HttpRequestIndicatorModule } from './http-request-indicator/http-request-indicator.module';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {NgxPaginationModule} from 'ngx-pagination';
import { GoogleAnalyticsService } from './config/services/google-analytics.service';
import {
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  PB_DIRECTION,
  POSITION,
  SPINNER
} from 'ngx-ui-loader';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
registerLocaleData(localeFr);

@Injectable()
export class SocketIO extends Socket {

    constructor() {
        super({
            url: environment.socketUrl,
            options: {
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 10
            }
        });
    }
}

// /********** Custom option for ngx-translate ******/
// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${environment.version}`);
// }

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "red",
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.chasingDots, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
};


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        CdkTableModule,
        SidebarModule.forRoot(),
        RoutingModule,
        TourNgBootstrapModule.forRoot(),
        NgbModalModule.forRoot(),
        NgxPaginationModule,
        AgmCoreModule.forRoot({ apiKey: 'AIzaSyBtdO5k6CRntAMJCF-H5uZjTCoSGX95cdk' }),
        PerfectScrollbarModule,
        MenuToggleModule,
      NgxUiLoaderModule,
      NgxUiLoaderRouterModule,
      NgxUiLoaderHttpModule,
        HttpClientModule,
        OverlayModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateService,
                // useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            timeOut: 2000,
            preventDuplicates: true
        }),
        ConfigModule,
        ErrorHandlingModule,
        HttpRequestIndicatorModule,
        ConfirmDialogModule,
        SocketIoModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    declarations: [
        ChankyaAppComponent,
        AuthComponent,
        TermsConditionsComponent,
    ],
    bootstrap: [ChankyaAppComponent],
    providers: [
        MenuItems,
        GoogleAnalyticsService,
        PageTitleService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        { provide: Socket, useClass: SocketIO }
    ],
    entryComponents: [
        LoadingComponent,
    ]

})
export class ChankyaAppModule { }
