import { Injectable, ErrorHandler } from '@angular/core'
import * as Sentry from '@sentry/browser'
import { RewriteFrames } from '@sentry/integrations'

import { environment } from './../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

    constructor() {
	// Plus de compte sentry disponible depuis des mois c'est désactivé donc 
        //Sentry.init({
        //    dsn: environment.sentryDsn,
        //    integrations: [
        //        new RewriteFrames(),
        //    ],
        //    environment: environment.name,
        //})
    }

    handleError(error) {
	// Plus de compte sentry disponible depuis des mois c'est désactivé donc 
        //Sentry.captureException(error.originalError || error);
        console.error(error);
    }
}
