import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConfigResolveService } from './config/services/config-resolve.service';
import { AuthGuardService } from './authentication/services/auth-guard.service';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';

export const AppRoutes: Routes = [

    {
        path: 'auth',
        loadChildren: './authentication/authentication.module#AuthenticationModule',
        resolve: {
            config: ConfigResolveService
        }
    },
    {
        path: '',
        loadChildren: './frontoffice/frontoffice.module#FrontofficeModule',
        canActivate: [AuthGuardService],
        resolve: {
            config: ConfigResolveService
        }
    },

    {
        path: 'terms-and-conditions',
        component: TermsConditionsComponent,
        // canActivate: [AuthGuardAdviserService],
        resolve: {
          config: ConfigResolveService,
        },
        data: {closeRouteCommand: ['../']}
      },

    {
        path: '**',
        redirectTo: 'auth'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(AppRoutes),
    ],
    exports: [RouterModule],
    providers: []
})
export class RoutingModule { }

